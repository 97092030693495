import Link from 'next/link';
import { ChevronRightSmall } from '@indriver/mireska';
import { Cell } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { ICatalogServiceSearchItem } from 'common/api';
import { recordEvent } from 'common/lib';
import { CategoryIcon } from 'common/ui';
import { ICityInfo } from 'entities/city';

export interface IOptionProps {
    cityInfo: ICityInfo;
    item: ICatalogServiceSearchItem;
}

export const Option = ({ cityInfo, item }: IOptionProps) => {
    const sendAnalytics = () => {
        recordEvent({
            actionAF: 'masters_customer_search_service_click',
            actionAmplitude: 'masters_customer_search_service_click',
            actionGA: 'masters_customer_search_service_click',
            payload: {
                city_id: cityInfo.id,
                city_name: cityInfo.name,
                country_id: cityInfo.countryId,
                country_name: cityInfo.countryName,
                category_name: item.category_slug,
                service_name: item.slug,
                search_found_service: 'true',
            },
        });
    };

    return (
        <Link href={`/${cityInfo.citySlug}/service/${item.slug}`} passHref>
            <Cell
                prefix={<CategoryIcon size={24} src={item.category_icon_url} />}
                subtitle={item.category_title}
                postfix={<ChevronRightSmall size={24} color={variables['text-and-icon-secondary']} />}
                onClick={sendAnalytics}>
                {item.name}
            </Cell>
        </Link>
    );
};
