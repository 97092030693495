import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import { ICategory } from 'common/api';
import { recordEvent } from 'common/lib';
import { DulledIndexSectionItem, IndexLayout, IndexSection, IndexSectionHeading, IndexSectionItem } from 'common/ui';
import { usePageCity } from 'entities/city';
import * as Styled from './category-list.styles';

interface IProps {
    id?: string;
    title: string;
    categories: ICategory[];
    search: React.ReactNode;
}

export const CategoryList = ({ id, categories, title, search }: IProps) => {
    const currentCity = usePageCity();
    const { t } = useTranslation();

    return (
        <section id={id}>
            <Styled.Header>
                <Styled.BlockHeading type='h2'>{title}</Styled.BlockHeading>
                {search && <Styled.SearchContainer>{search}</Styled.SearchContainer>}
            </Styled.Header>
            <IndexLayout>
                {categories.map((category) => (
                    <IndexSection key={category.id}>
                        <IndexSectionHeading type='h3'>{category.title}</IndexSectionHeading>
                        {(category.services.length > 8 ? category.services.slice(0, 6) : category.services).map(
                            (service) => (
                                <Link
                                    key={service.catalog_id}
                                    href={`/${currentCity.citySlug}/service/${service.slug}`}
                                    prefetch={false}
                                    passHref>
                                    <IndexSectionItem>{service.name}</IndexSectionItem>
                                </Link>
                            ),
                        )}
                        {category.services.length > 8 && (
                            <Link href={`/${currentCity.citySlug}/category/${category.slug}`} prefetch={false} passHref>
                                <DulledIndexSectionItem
                                    onClick={() => {
                                        recordEvent({
                                            actionAF: 'masters.customer.main_page_chose_category.click',
                                            actionAmplitude: 'masters.customer.main_page_chose_category.click',
                                            actionGA: 'masters_customer_mp_chose_category_click',
                                            payload: {
                                                city_id: currentCity.id,
                                                city_name: currentCity.name,
                                                country_id: currentCity.countryId,
                                                country_name: currentCity.countryName,
                                                category_id: category.id,
                                                category_name: category.slug,
                                            },
                                        });
                                    }}>
                                    {t('main_page_category_list_footer_show_all')}
                                </DulledIndexSectionItem>
                            </Link>
                        )}
                    </IndexSection>
                ))}
            </IndexLayout>
        </section>
    );
};
